import { useState } from 'react';
import './Dropdown.css';
import { TagCloud } from 'react-tagcloud';
import {
  removePreparation,
  getRecipe,
} from '../../model/recipe/recipeSlice';
import { useSelector, useDispatch } from 'react-redux';



const Dropdown = (props) => {
  const modPrepHandle = props.modPrepHandle;
  const label = props.label || 'Nessun titolo inserito';
  const index = props.index;
  const [active, setActive] = useState(props.active);
  const recipe = useSelector(getRecipe);
  const dispatch = useDispatch();
  let className = 'dropdown-item unselectable';
  let toggleClassName = 'fa fa-toggle-off';
  if (active) {
    className+= ' dropdown-active';
    toggleClassName = 'fa fa-toggle-on';
  }

  return ([
    <div key="prepHeaderView" className='dropdown-item-title unselectable'>
      <i  onClick={()=> setActive(!active)} style={{cursor:'pointer'}} className={toggleClassName} aria-hidden="true"></i>
      <label style={{cursor:'pointer'}} onClick={()=> setActive(!active)}>{recipe.preparations[index]?.title  || label}</label>
      <button title="Elimina" className="btn btn-normalize btn-action" onClick={(e) => {dispatch(removePreparation(index))}}>
        <i className="fa fa-minus" aria-hidden="true" style={{paddingRight: '0'}}></i>
      </button>
      <button title="Modifica" className="btn btn-normalize btn-action" onClick={(e) => {modPrepHandle(index)}}>
        <i className="fa fa-edit" aria-hidden="true" style={{paddingRight: '0'}}></i>
      </button>
   </div>,


    <div key='prepContentView' className={className}>
    {(recipe.preparations[index]?.tags && recipe.preparations[index]?.tags.length > 0 ) ? 

      <div className="dropdown-item-cell" style={{width:'20%'}}>
        <div className="dropdown-item-header">Tags</div>
        <div className="dropdown-item-content">
        <TagCloud id="tags"
          tags={recipe.preparations[index]?.tags}
          shuffle={false}
          disableRandomColor={true}
        />
        </div>
      </div> : <></>
      }
      {(recipe.preparations[index]?.ingredients && recipe.preparations[index]?.ingredients.length > 0 ) ? 
        <div key="ingredientsContainer" className="dropdown-item-cell"  style={{width:'30%'}}>
          <div className="dropdown-item-header" style={{paddingTop: "10px;"}}>Ingredienti</div>
          <div className="dropdown-item-content">
          {
            recipe.preparations[index]?.ingredients.map((key, i) => {
              return (
                <p key={key.id} className="dropdown-item-ingredient"><i class="fa fa-asterisk"></i> {key.value}</p>
              )
            })
          }
          </div>
        </div> : <></>
      }
      <div className="dropdown-item-cell"  style={{width:'50%'}}>
        <div className="dropdown-item-header"  style={{paddingTop: "10px;"}}>Descrizione</div>
        <div className="dropdown-item-content">
        {
          recipe.preparations[index].description?.split('.').map((key, i) => {
            return (
              <>
            {
              (key !== "")? 
              <p>{key}.</p>
              :
              <></>
            }
</>
            )
          })
        }
        </div>
      </div>
    </div>
  ]);

}

export default Dropdown;