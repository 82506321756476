/**
 * SideBar Class
 */
import React from 'react';
import './SideBar.css';
import './SwitchTheme';

const SideBar = () => {

  /**
   * Enable sticky effect on searchBar
   */
  React.useEffect(() => {
    var header = document.getElementById("sidebarContent");
    document.body.ontouchmove = () => {
        showHeaderSticky();
    };
    document.body.onscroll = () => {
        showHeaderSticky();
    };
    document.body.onscrollend = () => {
        showHeaderSticky();
    };
    window.onscroll = () => {
        showHeaderSticky();
    };
    function showHeaderSticky() {
      const scroll = window.scrollY === 0 ? document.body.scrollTop : window.scrollY; 
      const minScroll = window.scrollY === 0 ? 290 : 200; 
      if (scroll > minScroll) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  });


  return (
    <div id='sidebarContent'>
      <div key="0" id="buttonMenu" className='button' onClick={() => {
        document.getElementsByTagName("header")[0].classList.toggle("nav_is_visible");
        document.getElementsByTagName("main")[0].classList.toggle("nav_is_visible");
        (document.getElementById("advancedMenu").style.transform === "none") ? document.getElementById("advancedMenu").style.transform = "translateX(-150%)" : document.getElementById("advancedMenu").style.transform = "none";
      }}>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#17181c" stroke="none">
            <path d="M611 4274 c-120 -60 -157 -212 -77 -316 17 -24 50 -53 71 -65 l40 -23 1921 3 1920 2 37 25 c63 42 91 93 95 170 3 53 0 75 -16 107 -27 51 -81 99 -129 112 -26 8 -639 11 -1924 11 l-1885 0 -53 -26z" />
            <path d="M2956 3659 c-389 -54 -708 -282 -884 -634 -87 -176 -118 -328 -109 -554 6 -174 33 -285 102 -430 64 -133 127 -223 225 -321 276 -278 666 -392 1051 -310 74 16 134 37 272 93 27 11 33 6 360 -320 223 -224 344 -337 369 -348 69 -28 151 -16 217 33 39 29 81 112 81 162 0 92 -3 96 -343 438 -244 245 -319 326 -312 336 198 272 278 542 247 833 -27 259 -133 490 -310 675 -161 168 -358 279 -584 329 -104 23 -287 31 -382 18z m314 -354 c303 -71 532 -298 607 -601 24 -100 21 -276 -6 -378 -43 -160 -140 -314 -262 -414 -80 -66 -241 -145 -336 -166 -104 -23 -270 -21 -369 4 -416 107 -674 518 -588 938 61 295 289 534 584 611 94 25 276 28 370 6z" />
            <path d="M609 3260 c-156 -82 -156 -301 0 -382 l53 -28 372 0 c419 0 418 0 488 78 94 104 63 268 -64 333 -35 18 -63 19 -425 19 -362 -1 -390 -2 -424 -20z" />
            <path d="M609 2242 c-159 -83 -156 -299 5 -385 40 -22 49 -22 421 -22 370 0 381 1 420 22 139 74 164 247 50 351 -23 22 -60 45 -81 51 -25 7 -174 11 -400 11 l-362 0 -53 -28z" />
            <path d="M614 1231 c-104 -47 -151 -175 -103 -281 27 -60 45 -79 104 -106 l50 -24 861 0 860 0 51 25 c82 41 131 136 117 225 -10 59 -60 129 -113 157 l-44 23 -871 0 c-826 0 -873 -1 -912 -19z" />
          </g>
        </svg>
      </div>
      <div className="topnav">
        <input id="search" placeholder="Search.." className='search-bar' type="search" pattern=".*\S.*" onChange={(e) => { window.handleChange(e); }} />
        <label className="closebtn" htmlFor="search"><i className="fa fa-search"></i></label>
        <raga-switch-theme callback={'inputCallback'} />
      </div>
    </div>

  );
}
export default SideBar;