import React, { Component } from 'react';
import { 
  setContext
} from '../model/recipe/recipeSlice';
import { useDispatch } from 'react-redux';
import './Card.css';
import ShareBtn from './ShareBtn.js';
import { TagCloud } from 'react-tagcloud';
const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno","Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
class Card extends Component {
  constructor(props) {
    super(props);
    this.credits = this.props.credits;
    if (this.credits) {
      this.jsonObj = require('../json/'+this.credits+'/' + this.props.rm_id);
    } else {
      this.jsonObj = require('../json/' + this.props.rm_id);
    }
    this.state = {
      tags : []
    }
    this.filterTag = this.filterTag.bind(this);
    var filteredElement = [];
    for (var i = this.jsonObj.preparations.length - 1; i >= 0; i--) {
      if(this.jsonObj.preparations[i].tags){
        var clonedTags = [...this.jsonObj.preparations[i].tags];
        filteredElement = clonedTags.map(t => {return {...t}}).filter(t => this.filterTag(t));
      }
      this.state.tags.push(...filteredElement);
    }
  }
  onBadgeClilck(event, badge) {
    this.preventClick(event);
    console.log(badge);
    window.badgesChange(badge);
    if (document.getElementById("advancedMenu").style.transform !== "none"){
      this.toggleAdvancedMenu();
      setTimeout(this.toggleAdvancedMenu, 1200);
    }
  }
  changeDate(date) {
    var splittedDate = date?.split('-');
    if(splittedDate?.length === 3) {
      return splittedDate[0] + " " + monthNames[parseInt(splittedDate[1]) - 1] + " " + splittedDate[2]
    }
    return date;
  }
  preventClick(event) {
    event.stopPropagation();
    event.preventDefault(); 
  }
  filterTag(tag) {
    if (tag) {
      tag.props = null;
      tag.innerCount = 0;
    }
    return !this.state.tags.some((someEle) => {
      if (tag === undefined || tag.value === undefined) {
        return false;
      }
      if (someEle.value === tag.value) {
        if (someEle.innerCount) {
          someEle.innerCount = someEle.innerCount + 1;
        } else {
          someEle.innerCount = 1;
        }
        return true;
      }
      return false;
    });
  }
  toggleAdvancedMenu() {
    (document.getElementById("advancedMenu").style.transform==="none")?document.getElementById("advancedMenu").style.transform="translateX(-150%)":document.getElementById("advancedMenu").style.transform="none";
    document.getElementById("advancedMenu").style.zIndex = 999999;
  }
  render() {
    const badges = (this.jsonObj["badges"]) ? (this.jsonObj["badges"]) : [];
    const information ="";
    this.credits = this.props.credits;
    let prefix = "images/";
    let id = this.props.rm_id.substring(0,this.props.rm_id.length - 5 );
    let image = "images/no-thumb.jpg";
    if (this.props.rm_image) {
      if (this.credits) {
        prefix = prefix + this.credits + '/';
        id = this.credits + '/' + this.props.rm_id.substring(0,this.props.rm_id.length - 5 );
      }
      image = prefix + this.props.rm_image;
    }
    if (this.credits) {
      id = this.credits + '/' + this.props.rm_id.substring(0,this.props.rm_id.length - 5 );
    }
    const title = this.props.rm_title;
    const date = this.props.rm_date;

    const matchMedia = window.matchMedia('only screen and (max-width: 1000px)').matches;
    const minSize = matchMedia ? 27 : 12;
    const maxSize = matchMedia ? 30 : 15;
    const colorOptions ={luminosity: 'dark',hue: 'green',};
    if (id === undefined) {return("");}
    else {
      return (
        <div key={id} className="container">
          <div className="product-details unselectable">

            <div class="product-details-date">{this.changeDate(date)}</div>
            <Link context={id} title={title}/>
            <div className="badge-container">
              {
                badges.map((badge, i) =>
                  <div key={`badge${i}`} onClick={(e) => this.onBadgeClilck(e, badge)} className="badge-img"><img key={i} src={`/images/badges/${badge.value}.png`} alt={`${badge.value}`} title={`${badge.label}`} ></img></div>
              )}
            </div>
            <div id="tagCloud" onClick={this.preventClick}>
              <TagCloud id="cardTags"
                minSize={minSize}
                maxSize={maxSize}
                tags={this.state.tags}
                colorOptions={colorOptions}
                shuffle={false}
                onClick = {(t) => {
                  t.innerCount = 0;
                  window.tagsChange(t);
                  if (document.getElementById("advancedMenu").style.transform !== "none"){
                    this.toggleAdvancedMenu();
                    setTimeout(this.toggleAdvancedMenu, 1200);
                  }
                }}
                className="card-cloud"
              />
            </div>
            <p className="information">{information}</p>
            <ShareBtn shareUrl={`?detail=` + id} shareTitle={title} shareId={id} />
          </div>
          <div className="product-image unselectable">
            <img src={`/${image}`} alt="" />
            <div className="info">
              <h2> Description</h2>
              <ul>{
                this.jsonObj.preparations.map((element, i) =>
                  <li key={i}>
                    <PrepLink context={id} element={element}/>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Card;

export const Link = ({context, title}) => {
  const dispatch = useDispatch();
  return (
    <a href="#" onClick={() => {dispatch(setContext({context: context}));}}>
      <h1>{title}</h1>
    </a>
  );
}
export const PrepLink = ({context, element}) => {
  const dispatch = useDispatch();
  return (
    <strong>
      <a href={`#`+element.id}  onClick={() => {dispatch(setContext({context: context}));}}>{element.title}</a>
    </strong>
    );
}