import React, { Component } from 'react';
import Select from 'react-select';
import './AdvancedMenu.css';
import { lightOrDark } from '../utility/Element.js';
import { TagCloud } from 'react-tagcloud';
class AdvancedMenu extends Component {
	callback;
	constructor(props) {
		super(props);
		this.filterTagRef = React.createRef();
		this.badgeRefs = React.createRef();
		this.state={
			filteredTags: this.props.tags,
			selectedIngr: [],
			selectedBadge: [],
			selectedCredits: [],
			menuOpen:undefined,
			selectedFilter: {
				badges:[],
				tags:[],
				credits:[],
				ingredients: []
			},
			ingredients: this.props.ingredients,
      tags: this.props.tags,
      badges: this.props.badges,
      credits: this.props.credits
		};
    this.onIngredientsChange = this.onIngredientsChange.bind(this);
    this.onBadgesChange = this.onBadgesChange.bind(this);
    this.onCreditsChange = this.onCreditsChange.bind(this);
    this.onExternalBadgeSelection = this.onExternalBadgeSelection.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.onResetFilter = this.onResetFilter.bind(this);
    this.closeBadgeOption = this.closeBadgeOption.bind(this);
    this.handleChangeFilterTag = this.handleChangeFilterTag.bind(this);
    this.callback = this.props.callback;

	}
  componentDidMount() {
    document.getElementById("closeMenuButton").addEventListener("click", function(){
		document.getElementsByTagName("header")[0].classList.toggle("nav_is_visible");
		document.getElementsByTagName("main")[0].classList.toggle("nav_is_visible");
		(document.getElementById("advancedMenu").style.transform==="none")?document.getElementById("advancedMenu").style.transform="translateX(-150%)":document.getElementById("advancedMenu").style.transform="none";
		this.classList.toggle("active");
    });
	this.onResetFilter();
  }
	handleChangeFilterTag(e) { 
		var fTags = this.state.tags.filter((t) => t.value.toLowerCase().includes(e.target.value?.toLowerCase()));
		this.setState({
			filteredTags: fTags
		})
	};
	onIngredientsChange(options) {
		const ings = options.map((option) => {
			return option.value.id;
		});
		var sF = this.state.selectedFilter;
		sF.ingredients = ings;
		this.setState({
			selectedFilter: sF,
    	selectedIngr:options
		});
		this.callback(this.state.selectedFilter);
	}
	onExternalBadgeSelection(badge, reset){
		const index = this.state.selectedBadge.findIndex((b) => b.value === badge.value);
		if (reset) {
			this.resetFilter();
			this.onBadgesChange([badge]);
		} else {
			if (index === -1) {
				this.state.selectedBadge.push(badge);
			} else {
				this.state.selectedBadge.splice(index, 1);
			}
			this.onBadgesChange(this.state.selectedBadge);
			this.setState({menuOpen:true});
			this.badgeRefs.current?.blur();
			setTimeout(this.closeBadgeOption, 500);
		}
	}
	closeBadgeOption() {
		this.setState({menuOpen:undefined});
	}
  onCreditsChange(credits) {
		const crts = credits.map((credit) => {
			return credit.value;
		});
		var sF = this.state.selectedFilter;
		sF.credits = crts;
		this.setState({
			selectedFilter: sF,
    	selectedCredits: credits
		});
		this.callback(this.state.selectedFilter);
  }
  onBadgesChange(badge) {
    const bdgs = badge.map(b => b.value);
    var sF = this.state.selectedFilter;
    sF.badges = bdgs;
    this.setState({
    	selectedFilter: sF,
    	selectedBadge: badge
    });
    this.callback(this.state.selectedFilter);
  }
  onTagsChange(tag) {
  	tag.selected = !tag.selected;
  	const selectedTags = [];
  	const newTags = this.state.tags.map((ele)=>{
  		if (ele.value === tag.value){
	  		ele.selected = tag.selected;
      	ele.props= {
		      style: {
		      	cursor: 'pointer',
		        backgroundColor: tag.selected ? ele.props.style.color : 'unset',
		        color: tag.selected ? lightOrDark(ele.props.style.color) : ele.props.style.backgroundColor ,
		      },
		    };
  		}
  		if (ele.selected) {
  			selectedTags.push(ele.value);
  		}
  		return ele;
  	});
  	this.setState({
		tags: newTags,
		filteredTags: [...newTags]
	});
	var sF = this.state.selectedFilter;
  	sF.tags = selectedTags;
  	this.setState({selectedFilter: sF});
		this.callback(this.state.selectedFilter);
  }
  resetFilter(){
	this.state.tags.filter((t) => t.selected).forEach((t)=>this.onTagsChange(t));
	const sF = this.state.selectedFilter;
	sF.badges = [];
	sF.credits = [];
	sF.ingredients = [];
	this.filterTagRef.current.value = '';
	this.setState({
		selectedFilter: sF,
		selectedIngr:[],
		selectedBadge:[],
		selectedCredits:[],
		filteredTags:this.state.tags
	});
  }
  onResetFilter() {
	this.resetFilter(this);
	this.callback(this.state.selectedFilter);
  }
	render() {
		window["resetFilter"] = this.onResetFilter;
		window["tagsChange"] = this.onTagsChange;
		window["badgesChange"] = this.onExternalBadgeSelection;
		const matchMedia = window.matchMedia('only screen and (max-width: 1000px)').matches;
    const minSize = matchMedia ? 40 : 15;
    const maxSize = matchMedia ? 90 : 45;
		return ([
			<div key="1" id="advancedMenu" className='menu unselectable'>
				<h1>Ricerca avanzata</h1>
				<button className="btn close-menu" id="closeMenuButton"><i className="fa fa-times" aria-hidden="true"></i></button>
	  		<label id="ingredientsLabel" htmlFor="ingredients">Ingredienti:</label>
	      <Select id="ingredients"
	      	value={this.state.selectedIngr}
		      aria-labelledby="ingredientsLabel"
	      	aria-label="Ingredienti"
	      	aria-sort="ascending"
	        options={this.state.ingredients}
	        autoFocus
	        blurInputOnSelect
	        captureMenuScroll
	        closeMenuOnSelect
	        isMulti
	        isSearchable
	        className="simple-select"
					classNamePrefix="simple-select"
	        onChange={this.onIngredientsChange}
	        noOptionsMessage={() => "Nessun ingrediente"}
	        placeholder="Seleziona gli ingredienti.."
	      />

        <label id="badgesLabel" htmlFor="badges">Badges:</label>
        <Select id="badges"
        	ref={this.badgeRefs}
        	value={this.state.selectedBadge}
          aria-labelledby="badgesLabel"
          aria-label="Basges"
          options={this.state.badges}
          autoFocus
          blurInputOnSelect
          captureMenuScroll
          closeMenuOnSelect
          isMulti
          menuIsOpen={this.state.menuOpen}
          isSearchable
          className="badge-select"
          classNamePrefix="badge-select"
          formatOptionLabel={(badge) => (
            <div className="badge-selector" id="badgesUL">
              <div className="badge-img-select"><img src={`/images/badges/${badge.value}.png`} alt={badge.value} /></div>
              <div className="badge-label">{badge.label}</div>
            </div>
          )}
          onChange={this.onBadgesChange}
          noOptionsMessage={() => "Nessun badge"}
          placeholder="Seleziona i badge.."
        />
        <label id="creditsLabel" htmlFor="credits">Autori:</label>
        <Select id="credits"
			    value={this.state.selectedCredits}
          aria-labelledby="creditsLabel"
          aria-label="Credits"
          options={this.state.credits}
          autoFocus
          blurInputOnSelect
          captureMenuScroll
          closeMenuOnSelect
          isMulti
          menuIsOpen={this.state.menuOpen}
          isSearchable
          className="simple-select"
          classNamePrefix="simple-select"
          formatOptionLabel={(credit) => (
              <span className="credits-label">{credit.value}</span>
          )}
          onChange={this.onCreditsChange}
          noOptionsMessage={() => "Niente da visualizzare"}
          placeholder="Seleziona.."
        />

  		<label id="tagsLabel" htmlFor="tags">Tags:</label>
		  <input ref={this.filterTagRef} id="filterTags" type="text" placeholder='Filtra i tag' className="tag-cloud-filter tag-cloud-advanced" onChange={this.handleChangeFilterTag}/>
	      <TagCloud id="tags"
	        minSize={minSize}
	        maxSize={maxSize}
	        tags={this.state.filteredTags}
	        shuffle={false}
	        disableRandomColor={false}
	        className="tag-cloud"
	        onClick={this.onTagsChange}
	      />
				<button className="btn btn-search" onClick={this.onResetFilter}>Reset</button>
				<button className="btn btn-search" onClick={()=>{
		document.getElementsByTagName("header")[0].classList.toggle("nav_is_visible");
		document.getElementsByTagName("main")[0].classList.toggle("nav_is_visible");
		(document.getElementById("advancedMenu").style.transform==="none")?document.getElementById("advancedMenu").style.transform="translateX(-150%)":document.getElementById("advancedMenu").style.transform="none";
				}}>Chiudi</button>
			</div>, 
		])
	}
}
export default AdvancedMenu;

