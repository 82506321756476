import {
    Document,
    Page,
    Text,
    Image,
    StyleSheet,
    View
} from "@react-pdf/renderer";
import React from 'react';
class PDFDocument extends React.Component {
    // Create styles
    styles = StyleSheet.create({
        page: {
            zIndex: 1
        },
        ingredients: {
            display: 'flex',
            textAlign: 'left',
            flexDirection: "column",
            width: "20%",
            fontSize: 10,
            fontFamily: 'Times-Roman',
            borderRight: '1px solid black',
            borderTop: '1px solid black',
            paddingTop: '10px',
            paddingLeft: '10px'
        },
        prepTitle: {
            borderTop: '1px solid #EEE',
            marginTop: 15,
            paddingLeft: 10,
            paddingTop: 5,
            fontSize: 20,
            fontWeight: "bold",
            textAlign: 'center',
            fontFamily: 'Times-Roman',
        },
        title: {
            marginTop: 5,
            paddingTop: 15,
            paddingBottom:15,
            paddingLeft: 10,
            fontSize: 30,
            fontWeight: "bold",
            textAlign: 'center',
            fontFamily: 'Times-Roman',
        },
        preparations: {
            fontSize: 14,
            textAlign: 'justify',
            fontFamily: 'Times-Roman',
            width: "70%",
            borderLeft: '1px solid black',
            borderTop: '1px solid black',
            paddingLeft: '10px',
            paddingTop: '10px'
        },
        view: {
            display: 'flex',
            flexDirection: 'row',
            alignSelf: "center",
            width: "90%",
            margin: "10px"
        },
        point: {
            paddingLeft: 2,
            paddingRight: 4
        },
        credits: {
            fontSize: 14,
            textAlign: 'left',
            fontFamily: 'Times-Roman',
            borderTop: '1px solid #EEE',
            borderBottom: '1px solid #EEE',
            position: "absolute",
            bottom: 0,
            width: '100%'
        },
        logo: {
            position: 'absolute',
            top:3,
            left:50,
            width: '70px',
            height: '70px'
        },
    });
    constructor(props) {
        super(props);
        this.ricetta = this.props.ricetta;
    }
    render() {
        var ric = this.ricetta;
        return (
            <Document>
                <Page >
                    <Image src="logo192.png" style={this.styles.logo} />
                    <Text style={this.styles.title}>{ric.title}</Text>
                    <View style={{ maxHeight: 220, textAlign: 'center', maxWidth: '100%', overflow:'hidden' }}> 
                      <Image style={{ objectFit:'cover' }} src={"images/" + ric.image[0]} /> 
                    </View>

                    {ric.preparations.map((element, key) => {
                        return (
                            <View key={key} wrap={false}>
                                <Text key={key+'title'} style={this.styles.prepTitle}>{element.title}</Text>
                                <View key={key+'view'} style={this.styles.view} >
                                    <View key={key+'ings'} style={this.styles.ingredients} >
                                        {element.ingredients.map((ingr, key) => {
                                            return (
                                                <Text key={key+''+ingr.id} style={this.styles.point}>
                                                    <Text style={{color:"green", fontWeight:"bold"}}>• </Text> 
                                                    {ingr.value}
                                                </Text>
                                            )
                                        })}
                                    </View>
                                    <Text key={key+'preps'} style={this.styles.preparations}>{element.description.replaceAll('. ', '.\n')}</Text>
                                </View>
                            </View>
                        );
                    })
                    }
                </Page>
            </Document>
        );
    }

}
export default PDFDocument;