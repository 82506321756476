      function blowUp (self) {
        console.log(this);
        explode(self.getBoundingClientRect().x,self.getBoundingClientRect().y, self);
      }

      function explode(x, y, self) {
        var particles = 15,
          // explosion container and its reference to be able to delete it on animation end
        explosion = document.createElement("div");
        explosion.className = "explosion";
        self.remove();
        // put the explosion container into the body to be able to get it's size
        document.getElementById('addon').appendChild(explosion);

        // position the container to be centered on click
        explosion.style.left = x + 25 - 400 / 2 + "px";
        explosion.style.top = y + 25 - 180 - 400 / 2 + "px";

        for (var i = 0; i < particles; i++) {
          // positioning x,y of the particle on the circle (little randomized radius)
          x = (400 / 2) + rand(80, 150) * Math.cos(2 * Math.PI * i / rand(particles - 10, particles + 10));
          y = (400 / 2) + rand(80, 150) * Math.sin(2 * Math.PI * i / rand(particles - 10, particles + 10));
          var color = rand(0, 255) + ', ' + rand(0, 255) + ', ' + rand(0, 255), // randomize the color rgb
              // particle element creation (could be anything other than div)
            elm = document.createElement("div");
          elm.className = "particle";
          elm.style["background-color"] = 'rgb(' + color + ')';
          elm.style.top = y + 'px';
          elm.style.left = x + 'px';
          setTimeout(() => explosion.remove(),1500);
          explosion.appendChild(elm);
        }
      }

      // get random number between min and max value
      function rand(min, max) {
        return Math.floor(Math.random() * (max + 1)) + min;
      }
      var exportDef = {blowUp, rand}

export default exportDef;