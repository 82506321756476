function h(elemName, props, ...children) {
  const elem = document.createElement(elemName);
  if (props) {
    Object.assign(elem, props);
  }
  if (children) {
    children.forEach(child => {
      if (typeof child === 'object') {
        elem.appendChild(child);
      } else {
        elem.appendChild(document.createTextNode(child));
      }
    });
  }
  return elem;
}
function innerH(elemName, props, childrenAsString) {
  const elem = document.createElement(elemName);
  if (props) {
    Object.assign(elem, props);
  }
  if (childrenAsString && typeof child !== 'object') {
    elem.innerHTML = childrenAsString.trim();
  }
  return elem;
}

/**
 * Advanced Search Function
 * @param { Object } search {"ingredients": [], "preparation": string, "tags": []} search parameter
 * @param {*} ricetta ricetta in which to search the parameter search 
 * @returns ricetta formatted with correct founded element
 */
function advancedSearch(search, ricetta) {
  let prep = [];
  let trovato = false;
  let score = 0
  ricetta.preparations.forEach((preparation, i) => {
    trovato = false;
    if (search.preparation && preparation.title.toUpperCase().includes(search.preparation.toUpperCase())) {
      ricetta.preparations[i].retrievedWord = [];
      ricetta.preparations[i].retrievedWord.push(search.preparation.toUpperCase());
      trovato = true;
      score++;
    }

    if(search.credits.includes(preparation.credits)) {
      trovato = true;
      score++;
    }

    preparation.ingredients?.forEach((ingredient) => {
      if (search.ingredients && search.ingredients.includes(ingredient.id)) {
        ingredient.retrieved = true;
        trovato = true;
        score++;
      }else if (search.ingredients && !(search.ingredients.includes(ingredient.id))){
        ingredient.retrieved=false;
      }
    });
    preparation.tags?.forEach((tag) => {
      if (search.tags && search.tags.includes(tag.value)) {
        tag.retrieved = true;
        trovato = true;
        score++;
      }else if (search.tags && !search.tags.includes(tag.value)){
        tag.retrieved=false;
      }
    });
    preparation.badges?.forEach((badge) => {
      if (search.badges && search.badges.includes(badge.value)) {
        badge.retrieved = true;
        trovato = true;
        score++;
      }else if (search.badges && !search.badges.includes(badge.value)){
        badge.retrieved=false;
      }
    });
    ricetta.badges?.forEach(badge => {
      if (search.badges && search.badges.includes(badge.value)) {
        badge.retrieved = true;
        trovato = true;
        score++;
      }else if (search.badges && !search.badges.includes(badge.value)){
        badge.retrieved=false;
      }
    });
    if (trovato) {
      prep.push(preparation);
    }
  });

  if (prep.length !== 0) { ricetta.searched = true }
  ricetta.score = score;
  ricetta.preparations = prep;
  return ricetta;
}



/**
 * This is a JavaScript function that takes a color as an input, 
 * in the format of either a hex string (e.g. "#FF00FF") or an RGB string (e.g. "rgb(255, 0, 255)"). 
 * The function then calculates the "hsp" (perceived brightness) of the color using a formula that 
 * takes into account the relative intensity of the red, green, and blue color channels. 
 * 
 * @return If the hsp is greater than 127.5, the function returns "black", otherwise it returns "white".
 * 
 * The function first checks if the input color is in hex format by using the match() 
 * method with a regular expression to look for a "#" at the beginning of the string. 
 * If it is in hex format, it uses slice() and parseInt() to extract the red, green, and blue 
 * values from the string and convert them to integers. Next, the function uses the same process 
 * if the input color is in RGB format.
 * 
 * After that the function calculate the hsp by the following formula:
 * hsp = sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
 * 
 * The function checks the final hsp value with 127.5, 
 * if it is greater than that it returns 'black' otherwise it returns 'white'.
 * 
 * Please note that this formula is not the standard formula for calculating the perceived 
 * brightness of a color, other formula such as "Luma" are also used.
 */
function lightOrDark(color) {
  let hsp;
  if (color.match(/^#/)) {
    color = color.slice(1);
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  } else if (color.match(/^rgb/)) {
    color = color.match(/\d+/g);
    let r = color[0];
    let g = color[1];
    let b = color[2];
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  }
  return (hsp > 127.5) ? 'black' : 'white';
}

/**
 * This function generates a random hex color code as a string.
 * The hex color code is in the format of "#RRGGBB", 
 * where RR, GG, and BB are two-digit hexadecimal numbers representing the red, 
 * green, and blue components of the color, respectively.
 * 
 * The function starts by concatenating the "#" symbol with the result of 
 * an expression that generates a random number between 0 and 16777215 (inclusive). 
 * This number is then converted to a string with the base of 16 (hexadecimal) 
 * using the toString() method, which returns a string representation of the 
 * number in the specified base.
 * 
 * The implementation is using lightOrDark(color) to determine if the color is light or dark, 
 * and checks if the type argument passed to the function matches it, 
 * if it does, it returns the color, otherwise, it recursively calls itself, 
 * and repeat the process again until the type passed in matches the output of the 
 * lightOrDark function.
 * 
 * @param { String } type black or white color to define light or dark.
 * @return This specific implementation return a random Hex color. 
 * You may use it to style your pages or add random colors to any objects that you have.
 */
function randomHex(type) {
  let color = '#' + Math.floor(Math.random() * 16777215).toString(16);
  return type === lightOrDark(color) ? color : randomHex(type);
}

export { h, innerH, advancedSearch, lightOrDark, randomHex };