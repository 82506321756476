import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { TagCloud } from 'react-tagcloud';
import {
  copyPreparation,
  setPreparationTitle,
  setPreparationDescription, 
  setPreparationID,
  removeIngredient,
  addTag,
  removeTag,
  getPreparations,
  getRecipe,
  getTags
} from '../../model/recipe/recipeSlice';
import './PreparationForm.css';
import Tabs from '../Tabs/Tabs';

const PreparationForm = (props) => {
  const index = props.index;
  const [newTag, setNewTag] = useState('');
  const [tabsKey, setTabsKey] = useState('TbsId');
  const preparationOption = useSelector(getPreparations);
  const recipe = useSelector(getRecipe);
  const tags = [...useSelector(getTags)];
  const [filteredTag, setFilteredTag] = useState(tags)
  const dispatch = useDispatch();
  const modIngrHandle = props.modIngrHandle;
  const [error, setError] = useState(
    recipe.preparations[index]?.id === undefined ||
    recipe.preparations[index]?.id === ''
  );
  const handleChangeFilterTag = (e) => setFilteredTag(tags.filter((t) => t.value.toLowerCase().includes(e.target.value?.toLowerCase())));
  const handlekeyPressNewTag = (e) => {if (e.key === 'Enter') { handleClickNewTag() }};
  const handleChangeNewTag = (e) => setNewTag(e.target.value);
  const handleClickNewTag =  () => {
    if (newTag !== '') {
      dispatch(addTag({index: index, tag: {value: newTag.trim()}}));
      setNewTag('');
    }
  }
  const tagCloudAction = (value) => dispatch(addTag({index: index, tag: value}));
  return ([
    <div key='1' id="preparationForm" className='preparationForm'>
          <div>
            <label id="recipeLabel">Preparazioni precaricate</label>
            <Select id="recipe"
              aria-labelledby="recipeLabel"
              aria-label="Seleziona una ricetta da controllare"
              options={preparationOption}
              autoFocus={false}
              blurInputOnSelect
              closeMenuOnSelect
              isSearchable
              className="single-select"
              onChange={(option) => {
                if (option) { 
                  setTabsKey(tabsKey + 'true');
                  dispatch(copyPreparation({index: index, value: option.value}));
                  setError(false);
                } else {
                  setTabsKey(tabsKey + 'false');
                  dispatch(copyPreparation({index: index, value: undefined}));
                  setError(true);
                }
              }}
            />
            <label id="prepTitle" htmlFor="prepInput">ID: {recipe.preparations[index]?.id ? recipe.preparations[index]?.id : "ID preparazione"}</label>
            <div className={`input-container ${error ? 'input-error' : ''}`}>
              <input type="text" id="prepInput" value={recipe.preparations[index]?.title ? recipe.preparations[index]?.title : ''} onChange={(e) => {
                  dispatch(setPreparationTitle({index: index, value: e.target.value}));
                  dispatch(setPreparationID({index: index, value: e.target.value.replaceAll(' ', '_')}));
                  setError(e.target.value === undefined || e.target.value === '');
                }} />

            </div>
            <Tabs key={tabsKey}>
              <div label="Ingredienti">
                {
                  recipe.preparations[index]?.ingredients.map((key, i) => {
                    return (
                      <div key={key.value} className='ingredient-list unselectable'>
                        <i className="fa fa-asterisk" aria-hidden="true"></i>
                        <label>{key.value}</label>
                        <button title="Elimina" className="btn btn-normalize btn-action ingr-list-action" onClick={(e) => {dispatch(removeIngredient({preparation: index, ingredient: i}))}}>
                          <i className="fa fa-minus" aria-hidden="true" style={{paddingRight: '0'}}></i>
                        </button>
                        <button title="Modifica" className="btn btn-normalize btn-action ingr-list-action" onClick={(e) => {modIngrHandle({preparation: index, ingredient: i})}}>
                          <i className="fa fa-edit" aria-hidden="true" style={{paddingRight: '0'}}></i>
                        </button>
                     </div>
                    )
                  })
                }
              </div>
              <div label="Descrizione">
                <textarea className="dropdown-item-description" value={recipe.preparations[index].description} onChange={(e) => {
                dispatch(setPreparationDescription({index: index, value: e.target.value}));
                }} />
              </div>
              <div label="Tags">
                <div key="tagsContainer" id="tagsContainer">
                  <div>
                    <label id="selectedTagsLabel" htmlFor="selectedTags">Selected tags:</label>
                    <div className="tag-cloud-filter" key="selectedTags">
                    {
                      recipe.preparations[index].tags?.map((tag, i) => 
                        <span key={i} style={{margin:"0px 3px", cursor:"default"}} className="tag-cloud-tag unselectable">
                          {tag.value}
                          <span style={{fontSize:'15px', padding: '0 3px 0 5px', cursor:"pointer"}}
                            onClick={() => dispatch(removeTag({preparation: index, tag: i}))}
                          >
                            <i className="fa fa-close" aria-hidden="true"></i>
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label style={{marginRight: '35px'}} id="newTagsLabel" htmlFor="newTags">New tag:</label>
                    <input id="newTags" type="text" className="tag-cloud-filter new-tag" value={newTag}
                     onChange={handleChangeNewTag} onKeyPress={handlekeyPressNewTag} />
                    <button className="btn btn-normalize btn-new-tag" onClick={handleClickNewTag} >
                      <i className="fa fa-send" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div>
                    <label id="filterTagsLabel" htmlFor="filterTags">Filter tags:</label>
                    <input id="filterTags" type="text" className="tag-cloud-filter" onChange={handleChangeFilterTag}/>
                  </div>
                  <TagCloud id="tags"
                    tags={ filteredTag }
                    shuffle={false}
                    disableRandomColor={true}
                    className="tag-cloud"
                    onClick={tagCloudAction}
                  />

                </div>
              </div>
            </Tabs>
          </div>
    </div>
  ])
}

export default PreparationForm;