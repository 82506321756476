import {h, innerH} from '../utility/Element.js'
class SwitchTheme extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
  }
  connectedCallback() {
    this.css = getDefaultStyle();
    this.input = getInputTemplate();
    this.shadow.appendChild(this.css);
    this.shadow.appendChild(this.input);
    let inputCallback = this.getAttribute("callback");
    this.shadow.getElementById('Switch').addEventListener('change', e => {
			var element=document.getElementsByTagName('body')[0];
			window[inputCallback](element.classList.toggle('light'));
    })
  }
}

function getDefaultStyle() {
  return h('style', null, `

		.switch-theme {
		  display: inline-block;
		  position: relative;
		}

		.switch__input {
		  clip: rect(1px, 1px, 1px, 1px);
		  clip-path: inset(50%);
		  height: 1px;
		  width: 1px;
		  margin: -1px;
		  overflow: hidden;
		  padding: 0;
		  position: absolute;
		}

		.switch__label {
		  position: relative;
		  display: inline-block;
		  border-radius: 9999px;
		  cursor: pointer;
		  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
		}

		.switch__indicator {
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%) translateX(-72%);
		  display: block;
		  width: 40px;
		  height: 40px;
		  background-color: #7B7B7B;
		  border-radius: 9999px;
		  box-shadow: 10px 0px 0 0 rgba(0, 0, 0, 0.2) inset;
		}
		.switch__indicator::before, .switch__indicator::after {
		  position: absolute;
		  content: "";
		  display: block;
		  background-color: #FFFFFF;
		  border-radius: 9999px;
		}
		.switch__indicator::before {
		  top: 7px;
		  left: 7px;
		  width: 9px;
		  height: 9px;
		  background-color: #FFFFFF;
		  opacity: 0.6;
		}
		.switch__indicator::after {
		  bottom: 8px;
		  right: 6px;
		  width: 14px;
		  height: 14px;
		  background-color: #FFFFFF;
		  opacity: 0.8;
		}

		.switch__decoration {
		  position: absolute;
		  top: 65%;
		  left: 50%;
		  display: block;
		  width: 5px;
		  height: 5px;
		  background-color: #FFFFFF;
		  border-radius: 9999px;
		  animation: twinkle 0.8s infinite -0.6s;
		}
		.switch__decoration::before, .switch__decoration::after {
		  position: absolute;
		  display: block;
		  content: "";
		  width: 5px;
		  height: 5px;
		  background-color: #FFFFFF;
		  border-radius: 9999px;
		}
		.switch__decoration::before {
		  top: -20px;
		  left: 10px;
		  opacity: 1;
		  animation: twinkle 0.6s infinite;
		}
		.switch__decoration::after {
		  top: -7px;
		  left: 30px;
		  animation: twinkle 0.6s infinite -0.2s;
		}

		@keyframes twinkle {
		  50% {
		    opacity: 0.2;
		  }
		}
		.switch__indicator, .switch__indicator::before, .switch__indicator::after {
		  transition: all 0.4s cubic-bezier(0.46, 0.03, 0.52, 0.96);
		}

		.switch__input:checked + .switch__label {
		  background-color: #8FB5F5;
		  border-color: #347CF8;
		}
		.switch__input:checked + .switch__label .switch__indicator {
		  background-color: #ECD21F;
		  box-shadow: none;
		}
		.switch__input:checked + .switch__label .switch__indicator::before, .switch__input:checked + .switch__label .switch__indicator::after {
		  display: none;
		}
		.switch__input:checked + .switch__label .switch__decoration {
		  top: 50%;
		  transform: translate(0%, -50%);
		  animation: cloud 8s linear infinite;
		  width: 20px;
		  height: 20px;
		}
		.switch__input:checked + .switch__label .switch__decoration::before {
		  width: 10px;
		  height: 10px;
		  top: auto;
		  bottom: 0;
		  left: -8px;
		  animation: none;
		}
		.switch__input:checked + .switch__label .switch__decoration::after {
		  width: 15px;
		  height: 15px;
		  top: auto;
		  bottom: 0;
		  left: 16px;
		  animation: none;
		}
		.switch__input:checked + .switch__label .switch__decoration, .switch__input:checked + .switch__label .switch__decoration::before, .switch__input:checked + .switch__label .switch__decoration::after {
		  border-radius: 9999px 9999px 0 0;
		}
		.switch__input:checked + .switch__label .switch__decoration::after {
		  border-bottom-right-radius: 9999px;
		}

		@keyframes cloud {
		  0% {
		    transform: translate(0%, -50%);
		  }
		  50% {
		    transform: translate(-50%, -50%);
		  }
		  100% {
		    transform: translate(0%, -50%);
		  }
		}
  `);
}

function getInputTemplate() {
  return innerH(
    'div',
    { className: 'switch-theme', title: "Toggle dark/light", id: "switchTheme" },
    `
	    <input type="checkbox" class="switch__input" id="Switch">
	    <label class="switch__label" for="Switch">
	        <span class="switch__indicator"></span>
	        <span class="switch__decoration"></span>
	    </label>

    `
  );
}
window.customElements.define('raga-switch-theme', SwitchTheme);