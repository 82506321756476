import React, { useEffect } from 'react';
import './HighlightRecipe.css';
import './HighlightRecipeLight.css';
import '../external/js/fitText.js';

const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno","Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
const HighlightRecipe = ({ recipe }) => {
  useEffect(() => {
    // Update the document title using the browser API
    window.fitText(document.querySelector("h2"), 1.7);
    window.fitText(document.getElementsByClassName("highlight-container-prep-title"), 2.7);
    window.fitText(document.getElementById("recipeInfo"), 3.5);
  });

  return (
    <div className='highlight-container'>
      <Badges elements={recipe.badges}/>
      <div className='highlight-container-date'> {toDate(recipe.date)}</div>
      <h2>{recipe.title}</h2>
      <div id='recipeInfo' className="highlight-container-preps">{recipe.info}</div>
      <PrepList prep={recipe.preparations}/>
    </div>
)};
export default HighlightRecipe;

const Badges = ({elements}) => {
  let badgesValue = elements?.map(x => x.label).join(', ');
  return (
    <div className='highlight-container-badges'>{badgesValue}</div>
  );
}
const PrepList = ({prep}) => {
  return (
    <div key={0} className='highlight-container-preps'>
      {
        prep.map(p => {
          return <div className='highlight-container-prep-title'><a href={'#' + p.id }>{p.title}</a></div>
        })
      }
    </div>
  )
}

const toDate = (date) => {
  var splittedDate = date?.split('-');
  if(splittedDate?.length === 3) {
    return splittedDate[0] + " " + monthNames[parseInt(splittedDate[1]) - 1] + " " + splittedDate[2]
  }
  return date;
}