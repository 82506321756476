import React from 'react';
import './SearchResults.css';
const Preparation = ({ title, link, ingredients }) => {return (
      <div>
        <h2><a href={link}>{title}</a></h2>
        <ul className="search-result-ingredients">
        {ingredients.sort((a, b) => {
          return a.retrieved ? -1 : 1;
        }).map((ingr, index) => {
          return (
                  <li key={index} className={ingr.retrieved?'highlight-ing':''}>{ingr.label}</li>
                )})}
        </ul>
      </div>
)};

const SearchResult = ({ title, preparations, link, image }) => {
  if (image.indexOf('undefined') !==-1) {
    image = 'no-thumb.jpg';
  }
  return (
    <div className="search-result">
      <div className="search-result-link">
        <img src={`/images/${image}`} alt={title} />
        <div>
          <a className="search-result-a" href={link}>{link}</a>
          <h1><a href={link}>{title}</a></h1>
        </div>
      </div>
      <div className="search-result-content">
        {preparations.map((prep, index) => (
          <Preparation key={index} link={`${link}#${prep.id}`} title={prep.title} ingredients={prep.ingredients} />
        ))}
      </div>
    </div>
  )};
const SearchResults = ({ results, ingredients, tags, badges, credits }) => {
  if (credits===undefined || credits===null) {
    credits='';
  } else {
    credits=credits+`/`;
  }
  return (
    <div className="search-results">
      <div className='summary'>
        {results.length + " risultati"} <br />
        <b>Risultato ricerca per: </b> <br />
        {ingredients.filter(ingr => ingr.value.retrieved).length !==0 ? <b>ingredienti: </b>:""}
        {ingredients.filter(ingr => ingr.value.retrieved).map((element, i) => {
          return <span key={i} >{element.label + ", "}</span>
        })}
        {badges.filter(badge => badge.retrieved).length !== 0?<b>badges: </b>:""}
        {badges.filter(badge => badge.retrieved).map((element, i) => {
          return <span key={i} >{element.label + ", "}</span>
        })}
        {tags.filter(tag => tag.retrieved).length !== 0?<b>tags: </b>:""}
        {tags.filter(tag => tag.retrieved).map((element, i) => {
          return <span key={i} >{element.value + ", "}</span>
        })}
      </div>
      {results.map((result, index) => (
        <SearchResult
          key={index}
          title={result.title}
          preparations={result.preparations}
          link={`/?detail=` + credits + result.id.substring(0,result.id.indexOf('.json'))}
          credits={credits}
          image={credits +result.image[0]}
        />
      ))}
    </div>
  )};

export default SearchResults;
