import React, {useState, useEffect} from 'react'
import Card from './Card.js';

function InfiniteScrollCard(props) {
  const [items, setItems] = useState([])
  const [page, setPage] = useState(1)
  const [cacheKey] = useState(props.cacheKey);
  const { credits, cache} = props;
  const pageSize = 12;
  useEffect(() => {
    setItems([])
    setPage(1)
  }, [cacheKey])

  useEffect(() => {
    fetchData(page)
  }, [page])
 
  const fetchData = (page) => {
    setItems([
      ...items, 
      ...cacheKey
        .slice(pageSize *(page-1), pageSize *page)]);
  }
 
  const onScroll = () => {
    const scrollTop = document.documentElement.scrollTop
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
 
    if (scrollTop + clientHeight >= (scrollHeight - 1)) {
      setPage(page + 1)
    }
  }
 
  useEffect(() => {
    document.body.addEventListener('touchend', onScroll)
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      document.body.removeEventListener('touchend', onScroll)
    }
  }, [items])
 
  return (
    <>
    {
      items.map((key, i) => {
        if(credits) {
          return (<Card credits={credits} rm_date={cache[key].date} rm_id={cache[key].id} key={i} rm_image={cache[key].image[0]} rm_title={cache[key].title} />)
        } else {
          return (<Card rm_date={cache[key].date} rm_id={cache[key].id} key={i} rm_image={cache[key].image[0]} rm_title={cache[key].title} />)
        }
      })
    }
    </>
  )
}
 
export default InfiniteScrollCard
