import Header from '../../components/Header.js';
import Main from '../../components/Main.js';
import MetaTags from 'react-helmet';

const Home = (props) => {
  let { credits} = props;
  return ([
    <Header key="1" />,
    <Main key="2" credits={credits} />,
    <MetaTags key="3">
      <title>C@H - Cooking at home</title>
      <meta id="meta-description" property="og:description" content="Cooking at home, homemade recipes to make at home." />
      <meta name="description" content="Cooking at home, homemade recipes to make at home."/>
      <meta id="og-title" property="og:title" content="C@H - Cooking at home" />
      <meta id="og-image" property="og:image" content="https://cookinghome.netsons.org/logo.png" />
    </MetaTags>
  ]);
}
export default Home;
