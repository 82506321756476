import './App.css';
import './components/Nav.css';
import Header from './components/Header.js';
import Home from './feature/home/Home.js';
import Normalize from './feature/normalize/Normalize.js';
import Detail from './feature/detail/Detail.js';
import React,{ useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import {
  BrowserRouter as Router,
  Route,
  useHistory
} from "react-router-dom";
import { 
  getContext
} from './model/recipe/recipeSlice';
import { useSelector } from 'react-redux';


export default function App() {
  let name = useSelector(getContext);
  let internal = new URLSearchParams(window.location.search).get("internal");
  let credits = new URLSearchParams(window.location.search).get("credits");
  useHistory();
  if (internal === 'foo') {
    return (
      <Router>
        <Route path={`/`}>
          <Header key="1"/>
          <Normalize />
        </Route>
      </Router>
    );
  }
  return (
    <Router>
      <ScrollToTop smooth="true" className='on-top'/>
      <div className='naviga'>
        {name ? (
          <Detail name={name} credits={credits} />
          ) : (
          <Home credits={credits} />
        )}
      </div>
    </Router>
  );
}
