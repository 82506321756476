import './Theme.css';
import Select, { StylesConfig } from 'react-select';
import { 
  setTheme, 
} from '../../model/theme/themeSlice';
import { useDispatch } from 'react-redux';

const data = [
  {value: "default", label: "default"},
  {value: "first", label: "First"},
  {value: "second", label: "Second"},
  {value: "cz", label: "cz"},
  {value: "xmas", label: "xmas"}
];
const dot = (image = 'transparent') => {
  return({
  alignItems: 'center',
  display: image === 'transparent'? 'none':'flex',
  fontSize: 0,
  ':before': {
    backgroundImage: `url('/images/palette/${image}.png')`,
    border: '1px solid var(--text-color)',
    borderRadius: 10,
    content: '" "',
    display: 'block',
    margin: 'auto',
    height: 10,
    width: 100,
  },
})};
const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ 
    ...styles, 
    backgroundColor: 'var(--primary-color)',
    color: 'var(--text-color)',
    borderColor: 'var(--text-color)'
  }),
  menu: (styles) => ({ 
    ...styles,
    backgroundColor: 'var(--primary-color)',
    color: 'var(--text-color)',
    border: '1px solid var(--text-color)'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      alignItems: 'center',
      display: 'flex',
      fontSize: 0,
      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isSelected
        ? "var(--accent-primary-color)"
        : "transparent",
      ':hover': {
        ...styles[':hover'],
        backgroundColor: isSelected
            ? "var(--accent-primary-color)"
            : "var(--accent-secondary-color)"
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: "var(--accent-primary-color)"

      },
      ':before': {
        backgroundImage: `url('/images/palette/${data.value}.png')`,
        borderRadius: 10,
        border: '1px solid var(--text-color)',
        content: '" "',
        display: 'block',
        margin: 'auto',
        height: 50,
        width: 100,
      }
    };
  },
  input: (styles) => ({ display: 'none'}),
  placeholder: (styles) => ({ display: 'none' }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) }),
};

export const Theme = () => {
  const dispatch = useDispatch();
  const handleChangeTheme = (theme) => {
    const elements = document.getElementsByClassName("root-normalize-container");
    const themeSelector = document.getElementById("themeSelector");
    for (var i = 0; i < elements.length; i++) {
      const element = elements[i];
      for (var j = data.length - 1; j >= 0; j--) {
        const d = data[j];
        element.classList.remove(d.value);
        themeSelector.classList.remove(d.value)
      }
      element.classList.add(theme.value);
      themeSelector.classList.add(theme.value)
      dispatch(setTheme(theme.value))
    }
  }  
	return(
    <div id="themeSelector">
      <label htmlFor="themeSelect">Cambia il tema</label>
      <Select 
        aria-label="Cambia il tema utilizzato"
        id="themeSelect"
        defaultValue={data[0]}
        options={data}
        styles={colourStyles}
        onChange={handleChangeTheme}
      />
    </div>
  );
};
