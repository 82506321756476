import '../../components/Nav.css';
import '../../components/ImageGallery.js';
import './Detail.css';
import './DetailLight.css';
import $ from 'jquery';
import MetaTags from 'react-helmet';
import React, {ReactDOM, Component } from 'react';
import PDFDocument from '../../components/PDFDocument';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import ShareBtn from '../../components/ShareBtn.js';
import { 
  setContext
} from '../../model/recipe/recipeSlice';
import { useDispatch } from 'react-redux';

import HighligthRecipe from '../../components/HighlightRecipe.js';

import { withRouter } from "react-router-dom";
class Detail extends Component {
  preparations = [];
  credits = undefined;
  constructor(props) {
    super(props);
    this.state = {
      showPDF: false,
    };
    this.credits = new URLSearchParams(window.location.search).get("credits");
    this.handlePDFPreview = this.handlePDFPreview.bind(this);
  }
  componentDidUpdate() {
    document.getElementsByTagName("body")[0].scrollTo(0,0);
    resize();
  }
  handlePDFPreview(key) {
    this.setState({
      showPDF: true,
    });
  }
  render() {
    let { name } = this.props;
    const expansionFactor = window.matchMedia('only screen and (max-width: 1000px)').matches ? 2 : 1;
    window.scrollTo(0, 0);
    var rootElement = require("../../json/"+name+".json");
    var imageName = this.credits ? this.credits + "/" + rootElement.image[0] : rootElement.image[0];

    $(function() {
      $(window).on('scroll', function() {
        if ($('.content-wrapper').offset()) {
           
          if($(window).scrollTop() >= $('.content-wrapper').offset().top - 300) {
            $('.nav-bg').removeClass('bg-hidden');
            $('.nav-bg').addClass('bg-visible');
          } else {
            $('.nav-bg').removeClass('bg-visible');
            $('.nav-bg').addClass('bg-hidden');
          }
        }
      });
    });
    window['pdfPreview'] = this.handlePDFPreview;
    var shareStyle = expansionFactor===1? "{\"position\": \"fixed\", \"zIndex\": 10000, \"transform\": \"rotate(270deg)\", \"right\": \"-60px\", \"left\": \"unset\", \"top\": \"50vh\"}":  "{\"position\": \"fixed\", \"zIndex\": 10000, \"transform\": \"rotate(90deg)\", \"left\": \"-130px\", \"top\": \"50vh\"}"
  return([
    <div>
    { (this.state.showPDF) ?
      <div key="PDFViewer" id="PDFViewer" onClick={() => {this.setState({showPDF:false})}} className="pdf-viewer-container">
        <PDFViewer className="pdf-viewer">
          <PDFDocument ricetta={rootElement} />
        </ PDFViewer>
        <PDFDownloadLink document={<PDFDocument ricetta={rootElement} />} className="pdf-download btn" fileName={rootElement.title}>
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Download now!'
          }
        </ PDFDownloadLink>
      </ div> : 

      <></>
    }
    </div>,
    <ShareBtn key="10" shareUrl={`?detail=` + name} shareTitle={rootElement.title} shareId={name} shareStyle={shareStyle} />,
    <div className='detail-container'>
      <div className='detail-container-media' style={{backgroundImage: `url(images/${imageName})`}}>
        <div className="pre-nav">
          <nav className="links">
            <CH />
            <span className="line"></span>
          </nav>
        </div>
        <HighligthRecipe recipe={rootElement} />
        
      </div>
      <DetailAttribute 
        key="1" 
        elementTitle={rootElement.title} 
        preparations={rootElement.preparations.map(x=>{
          return x.title;
        })}
        preparationsId={rootElement.preparations.map(x=>{
          return x.id;
        })}
      />
      <div id="detail-description" key="2">
      {
        rootElement.preparations.map((element, j) => 
          <div id={element.id} className="content-wrapper">
            {(element.ingredients !==undefined && element.ingredients.length > 0)? (
              <div className="ing">
                <h2 style={{"padding-top":"30px", "border-top":"2px solid #fff8"}}>Ingredients</h2>

                <div id="checklist">
              {
                element.ingredients.map((ele, i) =>
                  <>
                  <input id={j+""+i} type="checkbox" name="r" value="1" />
                  <label for={j+""+i}>{ele.value?ele.value:ele}</label>
                  </>
              )}
              </div></div>) : (<></>)
            }
            <div className="descr">
            <h2 style={{"padding-top":"30px", "border-top":"2px solid #fff8"}}>{element.title}</h2>
              {
                element.description.split('.').filter(i => i).map((ele) =>
                <p>{ele + "."}</p>
              )}
            </div>
          </div>
      )}
      </div>
      <raga-photo-gallery key="0" images={rootElement.image} credits={this.credits} id={rootElement.id} photo-title={rootElement.title} />
    </div>,
    <MetaTags key="3">
      <title>C@H - {rootElement.title}</title>
      <meta id="meta-description" property="og:description" content="Cooking at home, homemade recipes to make at home." />
      <meta id="og-title" property="og:title" content={"C@H - " + rootElement.title} />
      <meta id="og-image" property="og:image" content={"https://cookinghome.netsons.org/images/"+ rootElement.image[0]} />
    </MetaTags>  
    ]);

  }

}

export default withRouter(Detail);

const DetailAttribute = (props) => {
  const title= props.elementTitle;
  const preparations = props.preparations
  const preparationsId = props.preparationsId
	return ([
      <div>
        <div 
          className="content-wrapper">
          <div className='desc main-list'>
            <h1 className='text-overlay'>{title}</h1>
            {
              preparations.map((element, j) => 
              <h2><a href={'#' + preparationsId[j] }>{element}</a></h2>
            )}
          </div>
        </div>
      </div>
  ])
}


const CH = () => {
  const dispatch = useDispatch();
  return (
    <a href="#" onClick={() => {dispatch(setContext({}));}}>
      Cooking@Home
    </a>
  );
}

const resize = () => {
  var p = document.querySelector('div.detail-container-media');

  p.addEventListener('click', function init() {
      p.removeEventListener('click', init, false);
      p.className = p.className + ' resizable';
      var resizer = document.createElement('div');
      resizer.className = 'resizer';
      p.appendChild(resizer);
      resizer.addEventListener('mousedown', initDrag, false);
  }, false);

  var startX, startY, startWidth, startHeight;

  function initDrag(e) {
    startX = e.clientX;
    startY = e.clientY;
    startWidth = parseInt(document.defaultView.getComputedStyle(p).width, 10);
    startHeight = parseInt(document.defaultView.getComputedStyle(p).height, 10);
    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
  }

  function doDrag(e) {
    p.style.width = (startWidth + e.clientX - startX) + 'px';
  }

  function stopDrag(e) {
      document.documentElement.removeEventListener('mousemove', doDrag, false);    document.documentElement.removeEventListener('mouseup', stopDrag, false);
  }

}