import "./RadioButton.css"
export const RadioButton = (props) => {
  const { changed, id, isSelected, label, value, image } = props;
  return (
    <div className="card">
      <input
        id={id}
        onClick={changed}
        onChange={() => {
        //do nothing
        }}
        value={value}
        type="radio"
        checked={isSelected}
        readOnly={false}
        className="card__input"
      /> 
      { (image === undefined) ? 
          <label className="card__body" htmlFor={id}>{label}</label>
        :
        <label className="card__body" htmlFor={id}>
          <div className="card__body-cover">
            <img className="card__body-cover-image" 
            src={'/images/badges/'+image+'.png'} alt={label} 
            title={label} />
            <span className="card__body-cover-checkbox"> 
                      <svg className="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </svg></span>
          </div>
        </label>
      }
    </div>
  );
};
