import { configureStore } from '@reduxjs/toolkit';
import recipeReducer from './recipe/recipeSlice';
import themeReducer from './theme/themeSlice';

export const store = configureStore({
  reducer: {
    recipe: recipeReducer,
    theme: themeReducer,
  },
});
