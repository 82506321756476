import React, { Component } from 'react';
import './Header.css';
import './Countdown.js';

//import confetti from '../external/js/confetti.js';
import blowUp from '../external/js/blowUp.js';
//import run from './xmas-spiral.js';

class Header extends Component {
  componentDidMount() {
//    setTimeout(function() {
//        confetti.start()
//    }, 1000);
//    setTimeout(function() {
//        confetti.stop()
//    }, 5000);
// run();
  };
  render() {
    return (
      <header className='cooking'>
  	    <canvas id="scene" width="100px" height="140px" style={{"position":"absolute","top":"20px","left":"20px","background":"transparent", "display": "block", "margin": "0 auto"}}></canvas>
        <div className="special-header" onClick={e => {
          var addon = document.getElementById('addon'),
          clientHeight = addon.clientHeight,
          clientWidth = addon.clientWidth;
          var elm = document.createElement("img");
          elm.style.top = blowUp.rand(20, clientHeight) + "px";
          elm.style.left = blowUp.rand(20, clientWidth) + "px";
          elm.style.transform = 'rotate('+blowUp.rand(0, 90) + "deg)";
          elm.addEventListener('click', e => blowUp.blowUp(e.target))
          elm.className='egg';
          elm.src = '/images/easter/easter-egg-icon-'+blowUp.rand(1,7)+'.png';
          addon.appendChild(elm);
        }}></div>
        <div className="special-header-end" 
            onClick={e => {
              var eggs = document.getElementsByClassName('egg');
              for (var i = eggs.length - 1; i >= 0; i--) {
                blowUp.blowUp(eggs[i]);
              }
            }}></div>
        <h1 onClick={() => window.open('/', '_self')}><span>C</span>{"ooking@Home"}</h1>
        <h4 onClick={() => window.open('/?internal=foo', '_self')}>Homemade recipes to make at home</h4>
        <raga-countdown countdown="Dec 25, 2022 00:00:00"/>
      </header>
    );
  }
}
export default Header;