import './CardHome.css';

const CardHome = (prop) => {

  const onBadgeClilck = (badge) => {
    window.badgesChange(badge, true);
    setTimeout(() => {
      document.getElementById("goToSearch").scrollIntoView({ behavior: "smooth", block: "start" });
      var header = document.getElementById("sidebarContent");
      header.classList.add("sticky");
    }, 100);
}

  const {value} = prop;
  const label = (value.charAt(0).toUpperCase() + value.slice(1)).replaceAll('-',' ');
  return (
  <div className="card-home">
    <div id={`${value}`} className="wrapper">
      <img id={`img-${value}`} alt={value} src={`images/home/${value}.jpg`} className="cover-image" />
    </div>
    <h1 className="title" >{`${label}`}</h1>
    <img 
      onClick={(e) => {
        onBadgeClilck({value: value, label: label});
      }}
      src={`images/home/${value}.png`} alt={value} className="character" />
  </div>

  )
}
export default CardHome;