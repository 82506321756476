import {
  copyIngredient,
  setIngredientLabel,
  setIngredientValue, 
  getRecipe,
  getIngredients,
} from '../../model/recipe/recipeSlice';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { useState } from 'react';

const IngredientForm = (props) => {
  const ingredients = useSelector(getIngredients);
  const recipe = useSelector(getRecipe);
  const dispatch = useDispatch();
  const indexes = props.indexes;
  const [errorVal, setErrorVal] = useState(
    recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.value === undefined ||
    recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.value === ''
  );
  const [errorLabel, setErrorLabel] = useState(
    recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.label === undefined ||
    recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.label === ''
  );
  const onIngredientsChange = (option) => {
    dispatch(copyIngredient({indexes: indexes, value: option}));
    setErrorLabel(false);
  }

	return ([
    <h2 key="ingrTitle">Crea o seleziona un ingrediente</h2>,
    <div key='1'>
      <label htmlFor="ingredients">Ingredienti precaricati</label>
      <Select id="ingredients"
        aria-labelledby="ingredientsLabel"
        aria-label="Ingredienti"
        aria-sort="ascending"
        autoFocus={false}
        options={ingredients}
        blurInputOnSelect
        closeMenuOnSelect
        isSearchable
        className="single-select"
        onChange={onIngredientsChange}
        noOptionsMessage={() => "Nessun ingrediente"}
        placeholder="Ingredienti precaricati"
      />

      <label style={{width: 'calc(100% - 40px)'}}>ID: {recipe.preparations[indexes.preparation].ingredients[indexes.ingredient].id}</label>
      <label htmlFor="ingredientLabel">Label: </label>
      <div className={`input-container ${errorLabel ? 'input-error' : ''}`}>
        <input 
          type="text" 
          id="ingredientLabel"
          value={recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.label}
          onChange={(e) => {
            setErrorLabel(e.target.value === undefined || e.target.value === '');
            dispatch(setIngredientLabel({indexes: indexes, label: e.target.value}));
          }}
        />
      </div>
      <label htmlFor="ingredientValue">Value: </label>
      <div className={`input-container ${errorVal ? 'input-error' : ''}`}>
        <input
          type="text"
          id="ingredientValue"
          value={recipe.preparations[indexes.preparation].ingredients[indexes.ingredient]?.value}
          onChange={(e) => {
            setErrorVal(e.target.value === undefined || e.target.value === '');
            dispatch(setIngredientValue({indexes: indexes, value: e.target.value}));
          }}
        />
      </div>
    </div>
  ])
}
export default IngredientForm;