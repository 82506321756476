import MetaTags from 'react-helmet';
import { useState } from 'react';
import NormalizeRecipe from '../../components/NormalizeRecipe';
import IngredientForm from '../../components/IngredientForm/IngredientForm';
import PreparationForm from '../../components/PreparationForm/PreparationForm';
import { Theme } from '../../components/Theme/Theme';
import { 
  addPreparation, 
  addIngredient, 
  getRecipe, 
} from '../../model/recipe/recipeSlice';
import { 
  getTheme, 
} from '../../model/theme/themeSlice';

import { useSelector, useDispatch } from 'react-redux';

const Normalize = () => {
  const path = window?.location.protocol + '//' + window?.location.host;
  const [modifyPrep, setModifyPrep] = useState();
  const [modifyIngr, setModifyIngr] = useState();
  const [normalize, setNormalize]= useState(true);
  const [preparation, setPreparation]= useState(false);
  const [ingredient, setIngredient]= useState(false);
  const dispatch = useDispatch();
  const recipe = useSelector(getRecipe);
  const selectedTheme = useSelector(getTheme);


  const modPrepHandle = (index) => {
    setModifyPrep(index);
    setNormalize(false);
    setPreparation(true);
    setIngredient(false);
  }
  const modIngrHandle = (indexes) => {
    setModifyIngr(indexes);
    setNormalize(false);
    setPreparation(false);
    setIngredient(true);
  }
    return ([
      <MetaTags key="metaTag">
        <title>C@H - Internal URL</title>
        <meta id="meta-description" property="og:description" content="Cooking at home, homemade recipes to make at home." />
        <meta name="description" content="Homemade recipes to make at home."/>
        <meta id="og-title" property="og:title" content="C@H - Internal URL" />
        <meta id="og-image" property="og:image" content={`${path}/logo.png`} />
      </MetaTags>,
      <Theme />
      ,
      <div key="normalize" id="normalize" className={`root-normalize-container ${selectedTheme.theme} ${normalize ? "swype-right" : "swype-left"}`}>
        <NormalizeRecipe modPrepHandle={modPrepHandle} />
        <div id="navigateButton" style={{'display': 'flex', 'padding': '20px 20px 20px 20px'}}>
          <button className="btn btn-normalize unselectable" title="Aggiungi una preparazione alla ricetta corrente" 
            onClick={(e) => {
              dispatch(addPreparation({callback:modPrepHandle}));
            }}>
            Aggiungi una preparazione <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
        <div id="saveButtons" style={{'display': 'flex', 'padding': '20px 20px 20px 20px'}}>
          <a className="btn btn-normalize unselectable"
            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(recipe))}`}
            download={`${recipe.title?.replaceAll(' ', '_')}.json`}
          >
            <i className="fa fa-download" aria-hidden="true"></i> Scarica in IT
          </a>
          <a className="btn btn-normalize unselectable" 
            href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(recipe))}`}
            download={`${recipe.title?.replaceAll(' ', '_')}.json`}
          >
            <i className="fa fa-download" aria-hidden="true"></i> Scarica in EN
          </a>
        </div>
      </div>,
      <div key="preparation" id="preparation" className={`root-normalize-container ${selectedTheme.theme} ${preparation ? "swype-right" : "swype-left"}`}>
      { (preparation) ? 
        <>
          <PreparationForm index={modifyPrep} modIngrHandle={modIngrHandle}/>
          <div id="navigateButton" style={{'display': 'flex', 'padding': '20px 20px 20px 20px'}}>
            <button className="btn btn-normalize unselectable" onClick={(e) => {
              setNormalize(true);
              setPreparation(false);
              setIngredient(false);
            }}>
              <i className="fa fa-check" aria-hidden="true"></i> Fatto
            </button>
            <button className="btn btn-normalize unselectable" title="Aggiungi un ingrediente alla preparazione corrente" 
              onClick={(e) => {
                dispatch(addIngredient({index: recipe?.preparations?.length - 1, callback: modIngrHandle}))
              }}>
              Aggiungi un ingrediente <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        </>: <></>
      }
      </div>,
      <div key="ingredient" id="ingredientInputForm"  className={`root-normalize-container ${selectedTheme.theme} ${ingredient ? "swype-right" : "swype-left"}`}>
      { (ingredient) ? 
        <>
          <IngredientForm indexes={modifyIngr} />
          <div id="navigateButton" style={{'display': 'flex', 'padding': '20px 20px 20px 20px'}}>
            <button className="btn btn-normalize unselectable" onClick={(e) => {
              setNormalize(false);
              setPreparation(true);
              setIngredient(false);
            }}>
              <i className="fa fa-check" aria-hidden="true"></i> Fatto
            </button>
          </div>
        </> : <></>
      }
      </div>
    ]);
}
export default Normalize;
