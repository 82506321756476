import React, { useState } from 'react';
import './Main.css';
import './SwitchTheme';
import CardHome from './CardHome/CardHome.js';
import AdvancedMenu from './AdvancedMenu.js';
import { randomHex, advancedSearch } from '../utility/Element.js';
import SearchResults from './SearchResults';
import InfiniteScrollCard from './InfiniteScrollCard';
import PDFDocument from './PDFDocument';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import SideBar from './Sidebar.js';
import { 
  getBadges,
  getMainTags,
  getMainIngredients,
	getMainCredits,
  getBlogger
} from '../model/recipe/recipeSlice';
import { useSelector } from 'react-redux';

var cache = {}
var filteredId = []
var searchCheck = {
  "titolo": true,
  "preparazione": true,
  "ingredienti": true
};
const Main = (props) => {
  let credits = useSelector(getBlogger);
  let resto = undefined;
  if (credits) {
    resto = require.context('../json/'+credits+'/', true, /\.json$/);
  } else {
    resto = require.context('../json/', false, /\.json$/);
  }
  const allCredits = useSelector(getMainCredits);
  const badges = useSelector(getBadges);
  const tags = useSelector(getMainTags);
  const ingredients = useSelector(getMainIngredients);
  const [check, setCheck] = useState(false);
  const [themeState, setThemeState] = useState(true);
  const [showPDF, setShowPDF] = useState(false);
  const [pdfPreview, setPdfPreview] = useState([]);
  const [cacheKey] = useState([]);
  const [searchedRecipe, setSearchedRecipe] = useState({});
  const advancedMenuCallback = (filter) => {
    let searchedEle = undefined;
    if (filter) {
      searchedEle = cacheKey.map((key, i) => {
        const recipe = { ...cache[key] };
        const searchResult = advancedSearch(filter, recipe);
        if (searchResult.preparations.length > 0) {
          searchResult.id = key;
          return searchResult;
        }
        return undefined;
      }).filter((x) => {
        return x !== undefined;
      }).sort((a, b) => {
        return b.score - a.score;
      });
    }
    setSearchedRecipe(searchedEle);
  }
  const inputCallback = (themeState) => {
    for (var i = tags.length - 1; i >= 0; i--) {
      let tag = tags[i];
      if (tag.selected) {
        tag.props = {
          style: {
            cursor: 'pointer',
            backgroundColor: themeState ? randomHex('white') : randomHex('black'),
            color: themeState ? 'white' : 'black',
          },
        }
      } else {
        tag.props = {
          style: {
            cursor: 'pointer',
            color: themeState ? randomHex('white') : randomHex('black'),
          }
        }
      }
    }
    setThemeState(themeState);
  }

  const handlePDFPreview = (key) => {
    setShowPDF(true);
    setPdfPreview(cache[key+'.json']);
  };
  window.handleChange = (e) => {
    filteredId = [];
    cacheKey.map((id, i) => {
      if (id.toLowerCase().includes(e.target.value.toLowerCase())) {
        filteredId.push(id);
      } else if (cache[id].title.toLowerCase().includes(e.target.value.toLowerCase()) && searchCheck['titolo']) {
        filteredId.push(id);
      }
      cache[id].preparations.forEach((key) => {
        if (key.title.toLowerCase().includes(e.target.value.toLowerCase()) && searchCheck['preparazione']) {
          filteredId.push(id);
        }
        key.ingredients.forEach((key) => {
          if (key.id || key.id === "") {
            if (searchCheck['ingredienti'] && (key.value.toLowerCase().includes(e.target.value.toLowerCase()) || key.id.toLowerCase().includes(e.target.value.toLowerCase()))) {
              filteredId.push(id);
            }
          } else {
            if (key.toLowerCase().includes(e.target.value.toLowerCase()) && searchCheck['ingredienti']) {
              filteredId.push(id);
            }
          }
        });
      });
      return filteredId
    })
    setCheck(true);
  }

  const importAll = (r) => {
    r.keys().sort((a, b) => {
      if (r(a).date === undefined )
        return 1;
      if (r(b).date === undefined )
        return -1;
      var splittedA = r(a).date.split('-');
      var splittedB = r(b).date.split('-');
      let date1 = new Date(splittedA[2], splittedA[1], splittedA[0]).getTime();
      let date2 = new Date(splittedB[2], splittedB[1], splittedB[0]).getTime();
      if (date1 > date2) {
        return -1;
      } else if (date1 < date2) {
        return 1;
      } else {
        return r(a).title.localeCompare(r(b).title)
      }

    }).forEach((key, i) => {
      (cache[key.substring(2)] = r(key));
      cache[key.substring(2)].id = key.substring(2);
    });
    cacheKey.splice(0, cacheKey.length);
    cacheKey.push(...Object.keys(cache));
  }
  importAll(resto);
  window['inputCallback'] = inputCallback;
  window['pdfPreview'] = handlePDFPreview;
  var keys = cacheKey.filter((i) => filteredId.includes(i) || !check);
  return ([
        <div>
        { (showPDF && pdfPreview) ?
          <div key="PDFViewer" id="PDFViewer" onClick={() => {setShowPDF(false)}} className="pdf-viewer-container">
            <PDFViewer className="pdf-viewer">
              <PDFDocument ricetta={pdfPreview} />
            </ PDFViewer>
            <PDFDownloadLink document={<PDFDocument ricetta={pdfPreview} />} className="pdf-download btn" fileName={pdfPreview.title}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download now!'
              }
            </ PDFDownloadLink>
          </ div> : 

          <></>
        }
        </div>,
        <AdvancedMenu key="0" ingredients={ingredients} tags={tags} badges={badges} credits={allCredits} callback={advancedMenuCallback} />,
        <main key="1">
          <SideBar />
          <div className='card-home-container'>
            <div id='cardCategories'>
          {
            badges.map((badge) => {
              return (
                <CardHome key={badge.value} value={`${badge.value}`}/>
              )
            })
          }
          </div>
          <div id='goToSearch' style={{width: '100%'}}></div>
          {
            (searchedRecipe === undefined || searchedRecipe.length === 0) ?
              <InfiniteScrollCard 
              key={keys.length}
                credits={credits} 
                cache={cache}
                cacheKey={keys}
              />
              : themeState ?
                <InfiniteScrollCard 
                  key={Object.keys(searchedRecipe).length}
                  credits={credits} 
                  cache={searchedRecipe}
                  cacheKey={Object.keys(searchedRecipe)}
                />
                :
                <InfiniteScrollCard 
                  key={Object.keys(searchedRecipe).length}
                  credits={credits} 
                  cache={searchedRecipe}
                  cacheKey={Object.keys(searchedRecipe)}
                />
          }
            </div>

        </main>]
  );


}

export default Main;