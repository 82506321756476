import {h} from '../utility/Element.js'
class Countdown extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: 'open' });
  }
  connectedCallback() {
    if (document.getElementsByTagName('body')[0].className && document.getElementsByTagName('body')[0].className.indexOf('conteggio') > -1) {
      defineCountdown(this.shadow, this.getAttribute("countdown"));
    }
  }
}
  function defineCountdown(shadowIN, data){
    // Set the date we're counting down to
    var countDownDate = new Date(data).getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

    // Get today's date and time
    var now = new Date().getTime();
        
    // Find the distance between now and the count down date
    var distance = countDownDate - now;
        
    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
    // Output the result in an element with id="demo"
    shadowIN.innerHTML =  "<p class=countdown> "+days + "d " + hours + "h " + minutes + "m " + seconds + "s </p>";
    var css;
    if(isDisparo(seconds)){
      css = getDefaultStyleGreen();
    }else{
      css = getDefaultStyleRed();
    }
    shadowIN.appendChild(css);

    // If the count down is over, write some text 
    if (distance < 0) {
        clearInterval(x);
        shadowIN.innerHTML = "";
    }
    }, 1000);
}
function isDisparo(num){
  let risultato = num % 2;
  if (risultato === 0){
    return false;
  }else if(risultato === 1){
    return true;
  }else{
    return 0;
  }
}
  function getDefaultStyleRed() {
    return h('style', null, `
    .countdown {
      display: block;
      position: absolute;
      font-weight: 600;
      margin-top: 130px;
      width: 100%;
      height: 58px;
      color: #84252d;
      font-size: xxx-large;
      text-align: end;
      padding: 0;
      font-family: 'Courier New', Courier, monospace; 
      text-shadow: 1px 1px #6e7e43;
      z-index: 1001;
    }`
      );
  }
  function getDefaultStyleGreen() {
    return h('style', null, `
    .countdown {
      display: block;
      position: absolute;ù
      font-weight: 600;
      margin-top: 130px;
      width: 100%;
      height: 58px;
      color: #6e7e43;
      font-size: xxx-large;
      text-align: end;
      padding: 0;
      font-family: 'Courier New', Courier, monospace; 
      text-shadow: 1px 1px #84252d;
      z-index: 1001;
    }`
      );
  }
window.customElements.define('raga-countdown', Countdown);