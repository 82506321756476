import { useState } from 'react';
import './NormalizeRecipe.css'
import './NormalizeRecipeMobile.css'
import { RadioButton } from './RadioButton/RadioButton';
import Dropdown from './Dropdown/Dropdown';
import { 
	addBadge, 
	removeBadge, 
	getRecipe, 
	setTitle, 
	getBadges
} from '../model/recipe/recipeSlice';
import { useSelector, useDispatch } from 'react-redux';


const NormalizeRecipe = (props) => {
	const [selectedBadge, setSelectedBadge] = useState([]);
  const dispatch = useDispatch();
  const badges = [...useSelector(getBadges)];
  const recipe = useSelector(getRecipe);
  const modPrepHandle = props.modPrepHandle;
	const [error, setError] = useState(
		recipe.title === undefined || recipe.title === ''
	);


	return ([
		<div key="pageTitle">
			<h1>Creazione nuova ricetta</h1>
			<h2>Assistente alla creazione di una nuova ricetta</h2>
			<p>
				Questa è una sezione interna del sito per assistere alla creazione del file JSON contenente le informazioni sulla ricetta. 
				Al file creato occorre aggiungere solo le immagini (preferibile siano in formato non troppo pesante e non enorme).
			 </p>
		</div>,
  	<div key="recipeAttribute" id="recipeAttribute">
  		<label id="recipeTitle" htmlFor="titleInput" className="unselectable">
  			<h2>{ (recipe.title) ? recipe.title: 'Inserisci il titolo della ricetta'}</h2>
  		</label>
  		<div key="titleInputContainer" className={`input-container ${error ? 'input-error' : ''}`} style={{width: 'calc(100% - 40px)', margin: '0 20px'}}>
	  		<input type="text" id="titleInput" value={recipe.title ? recipe.title : ''} onChange={(e) => {setError(e.target.value === undefined || e.target.value === '');dispatch(setTitle(e.target.value));}} />
  		</div>
  		<div key="badgesSection" id="badgesSection">
	  		<label id="radioBadgesLabel" htmlFor="radioBadges">
	  			<h2>Seleziona uno o più badge</h2>
	  		</label>
	  		<div id="radioBadges" key="radio" className="radio-btn-container">
	  		{
	  			badges.map((badge) => {
	  				return (
	  					<RadioButton 
	  						key={badge.value}
			          changed={(e) => {
									let tempArray = [...selectedBadge];
									const index = tempArray.indexOf(badge.value);
									if (index > -1) {
										dispatch(removeBadge(index));
									  tempArray.splice(index, 1);
									} else {
										dispatch(addBadge(badge));
			          		tempArray.push(badge.value);
			          	}
			          	setSelectedBadge(tempArray);
			          }}
			          id={badge.value}
			          isSelected={selectedBadge.includes(badge.value)}
			          label={badge.label}
			          image={badge.value}
			          value={badge.value}
			        />
	        	);
	  			})
	  		}
	      </div>
      </div>
    </div>,
    <div key="preparationsList" id="preparationsList">
    {recipe.preparations.map((p, index) => {
      return (
        <Dropdown key={index} index={index} modPrepHandle={modPrepHandle} />
      )})}
    </div>
	]);
}
export default NormalizeRecipe;